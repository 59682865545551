import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Hi`}</p>
    <p>{`I'm Kaan. This is my place on the internet 🌎.`}</p>
    <p>{`I'm a curious computer science grad and software developer from Turkey 🇹🇷, currently based in Berlin 🇩🇪. I am working on `}<a parentName="p" {...{
        "href": "https://twitter.com/sourcifyeth"
      }}>{`Sourcify`}</a>{` at the Ethereum Foundation.`}</p>
    <p>{`I write my thoughts, experiences, and some `}<inlineCode parentName="p">{`technical stuff`}</inlineCode>{` here.`}</p>
    <p>{`More `}<a parentName="p" {...{
        "href": "/about"
      }}>{`about me`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      